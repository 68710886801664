<template>
  <div class="edit-track-and-trace">
    <div class="edit-track-and-trace-header row" v-if="editingLocale">
      <button class="col-5 motivate-primary-button-outline" @click="cancel()">{{ $t('ui.cancel') }}</button>
      <div class="col"></div>
      <button class="col-5 motivate-primary-button" @click="save()">{{ $t('ui.save') }}</button>
    </div>
    <div class="edit-track-and-trace-content">
      <template v-if="editingLocale">
        <TrackAndTrace ref="trackAndTrace" v-if="getTrackAndTraceConfig" :editing="true"></TrackAndTrace>
      </template>
      <template v-else>
        <div class="row">
          <div class="col-12">
            <h5 class="choose-language"> {{ $t('ui.chooseLanguage')}} </h5>
          </div>
          <div class="col-4 " @click="setLocale('en')">
            <div class="row language-button" >
              <div class="col-12 language-icon">🇬🇧</div>
              <div class="col-12">{{ $t('trackAndTrace.header.languages.en')}}</div>
            </div>
          </div>
          <div class="col-4"  @click="setLocale('nl')">
            <div class="row language-button">
              <div class="col-12 language-icon">🇳🇱</div>
              <div class="col-12">{{ $t('trackAndTrace.header.languages.nl')}}</div>
            </div>
          </div>
          <div class="col-4"  @click="setLocale('de')">
            <div class="row language-button">
              <div class="col-12 language-icon">🇩🇪</div>
              <div class="col-12">{{ $t('trackAndTrace.header.languages.de')}}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TrackAndTrace from "../../../components/trackAndTrace/TrackAndTrace";
import {mapGetters} from "vuex";

export default {
  name: "EditTrackAndTrace",
  components: {TrackAndTrace},
  computed: {
    ...mapGetters('trackAndTrace', ['getTrackAndTraceConfig']),
    ...mapGetters('users', ['getClaims']),
    ...mapGetters(['getLocale', 'getCompanyId']),
    getEmptyConfig() {
      return {
        "activitiesColumn0": {
          "activities": [],
          "label": null
        },
        "activitiesColumn1": {
          "activities": [],
          "label": null
        },
        "activitiesColumn2": {
          "activities": [],
          "label": null
        },
        "activitiesColumn3": {
          "activities": [],
          "label": null
        },
        "bannerFileName": null,
        "bannerText": null,
        "companyId": this.getClaims.companyId,
        "created_at": new Date(),
        "customPropertyKey0": null,
        "customPropertyKey1": null,
        "customPropertyKey2": null,
        "customPropertyLabel0": null,
        "customPropertyLabel1": null,
        "customPropertyLabel2": null,
        "extraDuration": 0,
        "id": `${this.getClaims.companyId}_${this.getLocale}`,
        "informationLink": null,
        "informationText": null,
        "language": this.getLocale,
        "logoFileName": null,
        "orderNotFoundText": null,
        "orderNumberKey": null,
        "secretKeyLabel": null,
        "updated_at": new Date()
      }
    }
  },
  data() {
    return {
      editingLocale: null
    }
  },
  methods: {
    setLocale(locale) {
      this.editingLocale = locale;
      this.$store.commit('setLocale', locale);

      this.createInitialLocaleDoc(locale)
    },
    async createInitialLocaleDoc(locale) {
      const snapshot = await this.$FB.collection("trackAndTrace")
          .where('companyId', '==', this.getClaims.companyId)
          .where('language', '==', locale).get();
      if(!snapshot.docs.length) {
        await this.$store.dispatch('trackAndTrace/set', this.getEmptyConfig);
      }
    },
    cancel() {
      this.editingLocale = null;
      this.$store.commit('setLocale', null);
    },
    async save() {
      const saveResult = await this.$refs.trackAndTrace.save();
      const logoFileName = _.first(saveResult.headerResult).metadata.name;
      const bannerFileName = _.first(saveResult.bannerResult).metadata.name;
      const secretKeyLabel = saveResult.secretKeyLabel;
      const bannerText = saveResult.bannerText;
      const orderNotFoundText = saveResult.orderNotFoundText;
      const orderNumberKey = saveResult.orderNumberKey;
      const informationLink = saveResult.informationLink;
      const informationText = saveResult.informationText;

      const customPropertyKey0 = saveResult.customPropertyKey0;
      const customPropertyLabel0 = saveResult.customPropertyLabel0;
      const customPropertyKey1 = saveResult.customPropertyKey1;
      const customPropertyLabel1 = saveResult.customPropertyLabel1;
      const customPropertyKey2 = saveResult.customPropertyKey2;
      const customPropertyLabel2 = saveResult.customPropertyLabel2;

      const activitiesColumn0 = saveResult.activitiesColumn0;
      const activitiesColumn1 = saveResult.activitiesColumn1;
      const activitiesColumn2 = saveResult.activitiesColumn2;
      const activitiesColumn3 = saveResult.activitiesColumn3;

      const extraDuration = saveResult.extraDuration;

      const language = saveResult.language;


      const currentId = this.getTrackAndTraceConfig ? this.getTrackAndTraceConfig.id : null;
      const documentId = currentId ? currentId : `${this.getClaims.companyId}_${this.getLocale}`;


      const document = {
        id: documentId,
        companyId: this.getClaims.companyId,
        logoFileName,
        bannerFileName,
        bannerText,
        informationText,
        orderNotFoundText,
        secretKeyLabel,
        informationLink,
        orderNumberKey,
        customPropertyKey0,
        customPropertyLabel0,
        customPropertyKey1,
        customPropertyLabel1,
        customPropertyKey2,
        customPropertyLabel2,
        activitiesColumn0,
        activitiesColumn1,
        activitiesColumn2,
        activitiesColumn3,
        language,
        extraDuration: parseInt(extraDuration)
      }

      try {
        if (this.getTrackAndTraceConfig) {
          await this.$store.dispatch('trackAndTrace/patch', document);
        } else {
          await this.$store.dispatch('trackAndTrace/set', document);
        }
        this.$toasted.global.successRequest({
          message: this.$t('ui.saved')
        });
        this.cancel()
      } catch (e) {
        this.$toasted.global.failedFormRequest();
        console.error(e);
      }
    }
  }
}
</script>

<style scoped>
.edit-track-and-trace-content {
  border: 1px solid silver;
}

.edit-track-and-trace-header {
  margin-bottom: 12px;
}

.motivate-primary-button {
  width: 100%;
}

.choose-language {
  margin-bottom: 20px;
}

.language-button {
  text-align: center;
  border: 1px solid #2A52BE;
  margin: 20px;
  cursor: pointer;
  background: white;
  padding: 15px;
  border-radius: 4px;
}

.language-button:hover {
  background: #f1f1f1;
  color: #2A52BE;
}

.language-icon {
  font-size: 30px;
}

.language-button:active {
  background: #e7e7e7;
  color: #2A52BE;
}
</style>